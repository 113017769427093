import React from 'react'
import { graphql, Link } from 'gatsby'

import Seo from 'components/SEO'
import PageContent from 'components/Shared/_PageContent'
import Title from 'components/Shared/Title'
import { Image } from 'components/Shared/Image'
import AboutNav from 'components/Public/AboutNav'

import { org } from 'constant/public-org.constant'

//#region STYLES
import styled from 'styled-components'
const Wrapper = styled.div`
  width: 100%;
  ${p => p.theme.grid}
  --grid-template-columns: 1fr;
  --grid-template-rows: min(50vw, 1fr) 1fr;
  --justify-items: start;
  --align-items: start;
  --grid-gap: 2vw;
  --text-align: left;
  margin-bottom: calc(var(--margin-default) * 2.5);

  ${p => p.theme.mq.md`
    --grid-template-columns: repeat(12,1fr);
    --grid-template-rows: 1fr;
  `}
`
const ImageWrapper = styled.div`
  --grid-column: 1;
  ${p => p.theme.mq.md`
    --grid-column: 1/8;
  `}
  --grid-row: 1;
  width: 100%;
  margin-bottom: var(--margin-default);

  img {
    border-radius: 1rem;
  }
`
const Org = styled.div`
  --grid-column: 1;
  --grid-row: 2;
  ${p => p.theme.mq.md`
    --grid-column: 8/-1;
    --grid-row: 1;
  `}
  grid-column: var(--grid-column);
  grid-row: var(--grid-row);

  --height: 60rem;
  ${p => p.theme.mq.xs`
    --height: 50rem;
  `}
  ${p => p.theme.mq.md`
    --height: 80rem;
  `}
  width: 100%;
  height: var(--height);

  --flex-direction: column;
  --flex-wrap: wrap;
  --justify-content: flex-start;
  ${p => p.theme.flex};
`
const Group = styled.div`
  margin-bottom: 2rem;
  h2 {
    font-size: clamp(1.8rem, 2vw, 2.4rem);
    color: var(--color-primary);
  }
`
const Member = styled.div`
  --flex-direction: column;
  ${p => p.theme.flex}
`
//#endregion

const Staff = ({ data, location }) => {
  const {
    seo,
    imagefile: { childImageSharp: image },
  } = data
  const { title, description } = seo.frontmatter.staff

  return (
    <PageContent>
      <Seo title={title} description={description} />
      <AboutNav location={location} />
      <Title>組織及師資</Title>
      <Wrapper>
        <ImageWrapper>
          <Image image={image} alt='晶晶團隊' />
        </ImageWrapper>

        <Org>
          {org.map((member, i) => {
            return (
              <Group key={`group-${i}`} className={`group-${i}`}>
                <h2>{member.group}</h2>
                <Member>
                  {member.members.map((m, i) => (
                    <Link to={`/about/staff/${m}`} key={`member-${i}`}>
                      {m}
                    </Link>
                  ))}
                </Member>
              </Group>
            )
          })}
        </Org>
      </Wrapper>
    </PageContent>
  )
}

export default Staff

export const query = graphql`
  {
    seo: mdx(frontmatter: { type: { eq: "seo" } }) {
      frontmatter {
        staff {
          description
          title
        }
      }
    }
    imagefile: file(name: { eq: "staff" }) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`
