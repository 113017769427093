import React from 'react'

import { aboutLinks } from 'constant/links'

import SubLinks from 'components/Shared/SubLinks'

const AboutLink = ({ location }) => (
  <SubLinks links={aboutLinks} location={location} />
)

export default AboutLink
