/***************************************\

Organization data format
{
  group: "group name",
  members: []
},

**************************************/

export const org = [
  {
    group: "團長",
    members: [ "張智真" ]
  },
  {
    group: "音樂藝術諮詢顧問",
    members: [ "翁佳芬教授" ]
  },
  {
    group: "執行長",
    members: [ "朱娟瑩" ]
  },
  {
    group: "音樂總監",
    members: [ "鍾華琳" ]
  },
  {
    group: "財務",
    members: [ "呂錦涓" ]
  },
  {
    group: "資訊管理/班務",
    members: [ "葉怡君" ]
  },

  {
    group: "總務/班務",
    members: [ "蕭淑婷" ]
  },

  {
    group: "文宣美編/班務",
    members: [ "張琦紹", "蔡幸恕" ]
  },
  {
    group: "營隊活動/班務",
    members: [ "楊文玄" ]
  },
  {
    group: "指揮",
    members: [ "鍾華琳", "張瑋倩", "宋知庭", "林立君", "林威伶", "廖津敏",  "徐嘉欣" ]
  },
  {
    group: "鋼琴伴奏",
    members: [ "王雨絜", "黃世寧", "林晉陞", "楊雅婷" ]
  },
]
